import React, { useEffect, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styles from './ServerSearchFieldWithTags.module.scss';
import { ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import { pluck, reformatInputValue } from 'helpers/AppHelpers';
import { useDebounce } from 'use-debounce';

const Tag = ({ label, onDelete, ...props }) => (
  <div className={styles.tag} {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
);

export default function SearchInputWithTags({
  getLabel,
  fieldValue,
  options,
  onInputChange,
  onSelect,
  fieldClassName,
  listClassName,
  isInvalid
}) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 500);

  const onChange = (_, value, reason, processed) => {
    if (reason === 'remove-option') {
      onSelect(fieldValue.filter(({ id }) => id !== processed.option.id));
    } else {
      onSelect([...fieldValue, processed.option]);
    }
    setInputValue('');
  };

  const inputChangeHandle = (_, value, reason) => {
    if (reason === 'reset') return;

    setInputValue(reformatInputValue(value, 30));
  };

  useEffect(async () => {
    if (!debouncedInputValue && !fieldValue?.length) {
      setOpen(false);
      return;
    }

    onInputChange(debouncedInputValue);
    setOpen(true);
  }, [debouncedInputValue]);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl
  } = useAutocomplete({
    onChange: onChange,
    inputValue: inputValue,
    onInputChange: inputChangeHandle,
    value: fieldValue,
    multiple: true,
    options: options,
    clearOnEscape: true,
    getOptionLabel: (option) => getLabel(option),
    getOptionSelected: (option) => pluck(fieldValue, 'id').includes(option?.id),
    filterOptions: (options) => options,
    disableCloseOnSelect: true,
    open: open
  });

  const handleClickAway = () => setOpen(false);

  return (
    <NoSsr>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div {...getRootProps()}>
            <div
              ref={setAnchorEl}
              onClick={() => setOpen(true)}
              className={clsx(fieldClassName, styles.inputWrapper, isInvalid && styles.invalid)}>
              {fieldValue &&
                fieldValue?.map((option, index) => (
                  <Tag key={index} label={getLabel(option)} {...getTagProps({ index })} />
                ))}
              <input {...getInputProps()} />
            </div>
          </div>
          {groupedOptions.length > 0 && open ? (
            <ul className={clsx(listClassName, styles.listbox)} {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li key={index} {...getOptionProps({ option, index })}>
                  <span>{getLabel(option)}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </ClickAwayListener>
    </NoSsr>
  );
}
