import React, { useEffect, useState } from 'react';
import styles from './FormMultipleServerSearch.module.scss';

import ServerSearchFieldWithTags from 'components/ServerSearchFieldWithTags';

import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { searchAssets } from 'actions/commonActions';

import { allKeyboardLatSymbols, pluck } from 'helpers/AppHelpers';

import clsx from 'clsx';

export default function FormMultipleAssetSearch({ name, listName, className, getQueryOptions }) {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);

  const { control, clearErrors, setValue, getValues } = useFormContext();

  useEffect(() => {
    if (!getValues(name)?.length) {
      setOptions([]);
    }
  }, [getValues(name)]);

  const selectOption = (users, isInvalid) => {
    isInvalid && clearErrors(listName);
    setValue(name, pluck(users, 'id'));
    setValue(listName, users);
  };

  const changeInput = (searchQuery) => {
    const value = searchQuery ? searchQuery.replace(allKeyboardLatSymbols, '') : '';
    if (value === '') return;

    const filter = getQueryOptions(searchQuery);

    dispatch(searchAssets(filter)).then((res) => setOptions(res?.items || []));
  };

  return (
    <Controller
      name={listName}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <div className={clsx(className, styles.input_wrapper)}>
          <ServerSearchFieldWithTags
            getLabel={(option) => option?.drCode || ''}
            fieldValue={value || []}
            options={options}
            onInputChange={changeInput}
            onSelect={(data) => selectOption(data, !!error)}
            isInvalid={!!error}
          />
          {!!error && <span>{error.message}</span>}
        </div>
      )}
    />
  );
}
