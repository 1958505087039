import React, { useRef, useEffect, useState } from 'react';
import styles from './UserLogsSummary.module.scss';

import {
  SummaryWrapper,
  SummaryHeaderWrapper,
  SummaryHeader,
  SummaryTableControls,
  TableWrapper,
  TableHeadComponent,
  Pagination,
  SearchField,
  NoDataTableRow
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';

import { Table, TableBody } from '@material-ui/core';

import { tableConfig } from './tableConfig';

import FilterModal from './Filter';

import { useUserLogsActions, useUserLogsSelector } from 'hooks/UserLogs';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { dateTimeFormat, formatDate, getUserFullName } from 'helpers/AppHelpers';

import { ROOT_PATH } from 'constants/routeConstants';
import { USER_LOGS_TABLE_PARAMETER } from 'constants/configTableConstants';

import { generateChips, removeChip } from './helpers';

const defaultFilter = {
  filters: {
    searchQuery: '',
    startDate: null,
    endDate: null,
    userIds: [],
    historyEventTypes: [],
    createdAtUtc: null,
    entityId: null
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: []
};

export default function UserLogsSummary() {
  const history = useHistory();
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);
  const location = useLocation();

  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({});

  const [tableState, setTableState] = useState([]);

  const { isConfigReceived, getTableLimit } = useUserConfig();
  const {
    getUserLogsAction,
    setFilterAction,
    getUsersAction,
    getHistoryEventTypesAction,
    clearStateAction
  } = useUserLogsActions();
  const { filter } = useUserLogsSelector();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const generateInitialQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    const userIds = searchParams.getAll('userIds');
    const createdAtUtc = searchParams.get('createdAtUtc');
    const entityId = searchParams.get('entityId');

    if (userIds?.length && createdAtUtc && entityId) {
      return {
        ...defaultFilter,
        filters: {
          ...defaultFilter.filters,
          userIds,
          createdAtUtc,
          entityId
        },
        pagination: {
          ...defaultFilter.pagination,
          limit: getTableLimit(USER_LOGS_TABLE_PARAMETER)
        }
      };
    }

    return {
      filters: { ...defaultFilter.filters, ...filters },
      pagination: {
        ...defaultFilter.pagination,
        ...pagination,
        limit: getTableLimit(USER_LOGS_TABLE_PARAMETER)
      },
      sortRules: sortRules || defaultFilter.sortRules,
      responseReceived: false
    };
  };

  const getLogs = (query, isAutoload) => {
    setFilterAction(query);

    getUserLogsAction(query).then((tableData) => {
      setLogs(tableData, query, isAutoload);
    });
  };

  const setLogs = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getUsersAction({ shouldIncludeCurrentUser: true }).then((users) => {
      getHistoryEventTypesAction().then((eventTypes) => {
        setFilterCriteria({ users: users || [], eventTypes: eventTypes || [] });

        getLogs(generateInitialQuery());
      });
    });
  }, [isConfigReceived]);

  useEffect(() => {
    if (!filterCriteria?.users?.length) return;

    setChips(generateChips(filterCriteria, filters));
  }, [filter, filterCriteria]);

  useEffect(() => {
    isMobile && history.push(ROOT_PATH);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = (value) => getLogs(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getLogs(getChangedBySortFilter(filter, rowName));
  const changePage = (page, param) =>
    getLogs(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const applyFilter = (values) => getLogs(getChangedByApplyFilter(filter, values));
  const changeLimit = () =>
    getLogs(getChangedByLimitFilter(filter, getTableLimit(USER_LOGS_TABLE_PARAMETER)));

  const openFilterModal = () => setOpenFilter(true);

  const deleteChip = (chip) => {
    applyFilter(removeChip(filters, chip));
  };

  return (
    <SummaryWrapper>
      <FilterModal
        open={openFilter}
        setOpen={setOpenFilter}
        options={filterCriteria}
        onApply={applyFilter}
      />
      <SummaryHeaderWrapper>
        <SummaryHeader title="DRAT 2.0 logs" />
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || chips.length} />
            <FilterChips chips={chips} onDelete={deleteChip} />
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent config={tableConfig} sortRules={sortRules} onSortApply={sortTable} />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className={styles.nameCell}>
                    {getUserFullName(row?.user)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.timeCell}>
                    {formatDate(row.createdAtUtc, dateTimeFormat)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.actionCell}>
                    {row?.type?.displayName || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.messageCell}>
                    {row?.message || ''}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        selectedLimit={pagination?.limit}
        totalPages={pagination?.totalPages}
        tableRef={tableRef}
        tableName={USER_LOGS_TABLE_PARAMETER}
        onChangeLimit={changeLimit}
        onChangePage={changePage}
        withOptionAll
      />
    </SummaryWrapper>
  );
}
