import { createSelector } from 'reselect';

const assetData = (state) => state.assetData;

export const selectAssetsCounting = () => createSelector([assetData], (state) => state.counting);

export const selectSingleAssetData = () =>
  createSelector([assetData], (state) => state.currentAsset);

export const selectAssetTableSelectedColumnsData = () =>
  createSelector([assetData], (state) => state.customColumns);

export const selectFilterCriteriaData = () =>
  createSelector([assetData], (state) => state.assetsFilterCriteria);

export const selectAssetIspectionStatusesData = () =>
  createSelector([assetData], (state) => state.assetIspectionStatuses);

export const selectLocationHistoryData = () =>
  createSelector([assetData], (state) => state.locationHistoryData);

export const selectLocationHistoryFilterData = () =>
  createSelector([assetData], (state) => state.locationHistoryFilter);

export const selectInspectionHistoryData = () =>
  createSelector([assetData], (state) => state.inspectionHistoryData);

export const selectInspectionHistoryFilterData = () =>
  createSelector([assetData], (state) => state.inspectionHistoryFilter);

export const selectVerificationHistoryFilterData = () =>
  createSelector([assetData], (state) => state.verificationHistoryFilter);

export const selectVerificationHistoryData = () =>
  createSelector([assetData], (state) => state.verificationHistoryData);

export const selectAssetsFilterData = () =>
  createSelector([assetData], (state) => state.assetsFilter);

export const selectCreationFormData = () =>
  createSelector([assetData], (state) => state.creationFormData);

export const selectActiveAssetPrefixCode = () =>
  createSelector([assetData], (state) => state.activeAssetPrefix);

export const selectActiveAssetDRCode = () => createSelector([assetData], (state) => state.drCode);

export const selectAssetsFromSearch = () =>
  createSelector([assetData], (state) => state.assetsFromSearch);

export const selectProjectListData = () =>
  createSelector([assetData], (state) => state.projectList);

export const selectOpenedTicketsListData = () =>
  createSelector([assetData], (state) => state.openedTicketsList);

export const selectOpenedTicketsFilterData = () =>
  createSelector([assetData], (state) => state.openedTicketsFilter);

export const selectClosedTicketsListData = () =>
  createSelector([assetData], (state) => state.closedTicketsList);

export const selectClosedTicketsFilterData = () =>
  createSelector([assetData], (state) => state.closedTicketsFilter);

export const selectSelectedTabData = () =>
  createSelector([assetData], (state) => state.selectedTab);
