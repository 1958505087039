import React, { useRef, useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { selectUserConfig } from 'pages/commonSelectors';
import { setTopPosition, setUnsavedFormData } from 'actions/commonActions';
import {
  getSingleAsset,
  getAssetCreationForm,
  getAvailableDRCode,
  searchAssetByString,
  createAsset,
  updateAsset,
  clearAssetsState,
  getProjectList
} from 'actions/assetActions';
import {
  selectSingleAssetData,
  selectActiveAssetPrefixCode,
  selectActiveAssetDRCode,
  selectAssetsFromSearch,
  selectCreationFormData,
  selectProjectListData
} from '../selectors';
import {
  enrichNameToId,
  enrichDescriptionToName,
  getValidationObj,
  allKeyboardLatSymbols,
  isTeamMemberUser,
  reformatNumberValue,
  reformatInputValue,
  formatDate
} from 'helpers/AppHelpers';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import SelectInput from 'components/SelectInput/SelectInput';
import SearchInputWithTags from 'components/SearchInputWithTags/SearchInputWithTags';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import UploadFiles from 'components/UploadFiles/UploadFiles';
import InputDRCodeWithChips from 'components/InputDRCodeWithChips/InputDRCodeWithChips';
import MainButton from 'components/StyledComponents/MainButton';
import { TextField, Checkbox } from '@material-ui/core';
import { ASSETS_LIST_PATH, ASSET_DETAILED_PATH, ROOT_PATH } from 'constants/routeConstants';
import {
  CREATE_NEW_ASSET,
  NEW_ASSET_TYPE,
  UPDATE_ASSET,
  UPDATE_ASSET_TYPE,
  EXIT_WITHOUT_SAVING,
  WITHOUT_SAVING_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE
} from 'constants/dialogPopupsData';
import styles from './AssetEdit.module.scss';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';
import StyledDatePicker from 'components/StyledDatePicker/StyledDatePicker';
import moment from 'moment';
import ImagesUpload from 'components/FilesUploader/ImagesUpload/ImagesUpload';

function AssetEdit({
  userConfig,
  setUnsavedFormDataAction,
  currentAsset,
  activeAssetPrefix,
  getCurrentAssetAction,
  creationFormData,
  getCreationFormAction,
  drCodeData,
  getAvailableDRCodeAction,
  assetsFromSearchData,
  searchAssetByStringAction,
  createAssetAction,
  updateAssetAction,
  projectList,
  getProjectListAction,
  setTopPositionAction,
  clearAssetsStateAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const containerRef = useRef(null);
  const excludedAssetIds = useRef([]);
  const history = useHistory();
  const { userRole } = userConfig;

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [savePressed, setSavePressed] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [routeUrl, setRouteUrl] = useState('');
  const [refreshKey, setRefreshKey] = useState(new Date().getTime() + Math.random());
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const [values, setValues] = useState({
    assetPrefix: { ...activeAssetPrefix },
    rental: {
      rentalStatus: { name: 'Available', displayName: 'Available' }
    },
    isNonOwned: false,
    labeled: id ? null : true,
    isInPicklist: { id: 0, name: 'No' },
    isInLeavelist: { id: 0, name: 'No' }
  });
  const [availableDRCode, setAvailableDRCode] = useState({});
  const [assetOptions, setAssetOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [initValues, setInitValues] = useState({ ...values });
  const [changeLocation, setChangeLocation] = useState({ location: {}, sublocation: {} });

  const {
    resources,
    assetPrefix,
    assetFields,
    assetLicense,
    rental,
    contents,
    lastLocationHistory,
    containedInAsset
  } = values;

  const [assetFilter, setAssetFilter] = useState({
    pagination: {
      limit: 40,
      page: 1
    },
    filters: {
      prefixTypes: [2, 3, 4, 5], // search assets only for trailers and containers
      isDeleted: false,
      excludeAssetIds: [id ? +id : null],
      searchQuery: containedInAsset?.drCode || ''
    }
  });

  const { assetPrefixes, locations, grades, rentalStatuses, states, defaultLocation } =
    creationFormData;

  const changeStatusToAvailable = () => {
    setTimeout(() => {
      setAvailableDRCode({ ...availableDRCode, isAvailable: true });
    }, 1000);
  };

  useEffect(() => {
    if (id) {
      getCurrentAssetAction(id);
    }
    if (!id && isTeamMemberUser(userRole)) {
      history.push(ROOT_PATH);
    }
  }, [id, userRole]);

  useEffect(() => {
    if (id && currentAsset?.id) {
      setValues({ ...values, ...currentAsset });
      setInitValues({ ...values, ...currentAsset });
      excludedAssetIds.current = [+id, +currentAsset?.containedInAsset?.id || null];
      setBreadcrumbs([
        {
          path: `${ASSET_DETAILED_PATH}/${id}`,
          name: currentAsset.drCode
        },
        { name: `Edit Asset` }
      ]);
    }
  }, [id, currentAsset]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    getProjectListAction().then(
      (res) => res?.length && setProjectOptions([{ id: '-', name: '-', displayName: '-' }, ...res])
    );
  }, []);

  useEffect(() => {
    if (activeAssetPrefix.id && assetPrefixes?.length && !id) {
      getAvailableDRCodeAction(activeAssetPrefix);
      setValues({
        ...values,
        assetPrefix: {
          ...assetPrefixes?.find((asset) => asset.id === activeAssetPrefix.id)
        },
        assetFields: assetPrefixes
          ?.find((prefix) => prefix.id === activeAssetPrefix?.id)
          ?.assetFields?.map((item) => {
            return { assetField: item, value: null };
          })
      });
      setBreadcrumbs(
        [
          activeAssetPrefix?.drCode
            ? {
                path: `${ASSET_DETAILED_PATH}/${activeAssetPrefix?.drId}`,
                name: activeAssetPrefix?.drCode
              }
            : null,
          { name: 'Add Asset' }
        ].filter(Boolean)
      );
    }
  }, [activeAssetPrefix, assetPrefixes]);

  useEffect(() => {
    if (drCodeData) {
      setAvailableDRCode(drCodeData);
      const value = { ...values, drCode: drCodeData.availableDrCode };
      setValues(value);
      setInitValues(value);
      validateForm('drCode', '');
      if (drCodeData.isAvailable === false) {
        changeStatusToAvailable();
      }
    }
  }, [drCodeData]);

  useEffect(() => {
    if (assetsFromSearchData?.items?.length) {
      setAssetOptions(assetsFromSearchData?.items);
    }
  }, [assetsFromSearchData]);

  useEffect(() => {
    return () => {
      setAssetOptions([]);
      clearAssetsStateAction();
    };
  }, []);

  const formIsNotChanged = JSON.stringify(initValues) === JSON.stringify(values);

  useEffect(() => {
    setUnsavedFormDataAction(!formIsNotChanged);
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [values]);

  const getDetails = () => {
    const detailsSummary = assetFields?.map((asset) => {
      return { ...asset.assetField, value: asset.value };
    });

    return [
      detailsSummary?.slice(0, Math.ceil(detailsSummary.length / 2)),
      detailsSummary?.slice(Math.ceil(detailsSummary.length / 2), detailsSummary.length)
    ];
  };

  const getAssetIndex = (id) =>
    values.assetFields?.findIndex(({ assetField }) => assetField.id === id);
  const getAssetValue = (id) =>
    values.assetFields?.find(({ assetField }) => assetField.id === id)?.value || '';

  const onAssetFilterChange = (searchQuery) => {
    const value = searchQuery ? searchQuery.replace(allKeyboardLatSymbols, '') : '';
    if (value !== '') {
      const contentsIds = values?.contents?.length
        ? [...values?.contents?.map(({ id }) => id)]
        : [];
      const excluded = id ? [+id, ...contentsIds] : [...contentsIds];
      const currFilter = {
        ...assetFilter,
        filters: {
          ...assetFilter.filters,
          excludeAssetIds: excluded,
          searchQuery
        }
      };
      setAssetFilter(currFilter);
      searchAssetByStringAction(currFilter);
    }
  };

  const onInput = (event) => {
    const key = event.target.name;
    let value = event.target.value;
    validateForm(key, '');

    if (value) {
      if (key === 'drCode') {
        if (!value.includes(values.drCode?.split('-')[0] + '-')) {
          value = values.drCode;
        }
        value = value?.substr(0, 50) || '';
        getAvailableDRCodeAction({ id: values.assetPrefix?.id, drCode: value });
      }
      if (key === 'modelYear') {
        value = reformatInputValue(value, 4, /[^0-9]/g);
      }
      if (key === 'drCode') {
        value = reformatInputValue(value, 50, /[^0-9A-Za-z \\-]/g);
      }
      if (key === 'licensePlate') {
        value = reformatInputValue(value, 20, /[^0-9A-Za-z\\-]/g);
      }
      if (key === 'containedIn') {
        value = reformatInputValue(value, 20, /[^0-9A-Za-z\\-]/g);
      }
      if (key === 'purchasedFrom') {
        value = reformatInputValue(value, 40, allKeyboardLatSymbols);
      }
      if (key === 'shortNote' || key === 'make' || key === 'model') {
        value = reformatInputValue(value, 255, allKeyboardLatSymbols);
      }
      if (key === 'changeNotes') {
        value = reformatInputValue(value, 15000, allKeyboardLatSymbols);
      }
      if (key === 'notes') {
        value = reformatInputValue(value, 15000, allKeyboardLatSymbols);
      }
    }

    if (key === 'changeNotes') {
      setValues({
        ...values,
        lastLocationHistory: {
          ...lastLocationHistory,
          changeNotes: value?.substr(0, 15000) || null
        }
      });
    } else if (key === 'licensePlate' || key === 'make' || key === 'model' || key === 'modelYear') {
      setValues({ ...values, assetLicense: { ...assetLicense, [key]: value } });
    } else {
      setValues({ ...values, [key]: value || null });
    }
  };

  const onDetailsInput = (event, cell) => {
    const { type, constraints } = cell;
    const key = event.target.name;
    const value = event.target.value;
    const newValues = [...assetFields];
    switch (type) {
      case 'String':
        newValues[key].value = reformatInputValue(value, 60, allKeyboardLatSymbols);
        break;
      case 'Int':
        newValues[key].value = reformatNumberValue(value);
        break;
      case 'Float':
        newValues[key].value = reformatNumberValue(value);
        break;
      default:
        newValues[key].value = value;
        break;
    }
    if (constraints?.maxLength && type === 'String') {
      newValues[key].value = newValues[key].value.toString().substr(0, constraints.maxLength);
    }
    setValues({ ...values, assetFields: newValues });
  };

  const onDetailsCheck = (event) => {
    const newValues = [...assetFields];
    const index = newValues.findIndex(({ assetField }) => assetField.name === event.target.name);
    newValues[index].value = !newValues[index].value;
    setValues({ ...values, assetFields: newValues });
  };

  const onSelect = (selectedItem) => {
    if (selectedItem.name === 'licenseState') {
      setValues({
        ...values,
        assetLicense: { ...assetLicense, [selectedItem.name]: selectedItem.value }
      });
    } else {
      setValues({ ...values, [selectedItem.name]: selectedItem.value });
    }
    validateForm(selectedItem.name, '');
  };

  const onTypeSelect = (name, value) => {
    getAvailableDRCodeAction(value);
    setValues({
      ...values,
      [name]: value,
      assetFields: assetPrefixes
        ?.find((prefix) => prefix.id === value?.id)
        ?.assetFields?.map((item) => {
          return { assetField: item, value: null };
        })
    });
  };

  const onRentalSelect = (selectedItem) => {
    if (selectedItem.name === 'rentalStatus') {
      setValues({
        ...values,
        rental: {
          destination: values?.rental?.destination || null,
          [selectedItem.name]: selectedItem.value
        }
      });
    } else {
      setValues({ ...values, rental: { ...rental, [selectedItem.name]: selectedItem.value } });
    }
  };

  const onProjectSelect = (name, value) => {
    validateForm('rental', '');
    setValues({ ...values, rental: { ...rental, [name]: value } });
  };

  const onChangeLocationSelect = (name, value) => {
    const locationObj = {
      ...changeLocation,
      [name]: value,
      sublocation: name === 'location' ? null : value
    };
    setChangeLocation({ ...changeLocation, ...locationObj });
    setValues({
      ...values,
      lastLocationHistory: { ...lastLocationHistory, id: null, ...locationObj }
    });
    setRefreshKey(new Date().getTime() + Math.random());
    validateForm('location', '');
  };

  const onDetailsSelect = (data, name) => {
    const newValues = [...assetFields];
    const index = newValues.findIndex(
      ({ assetField }) => assetField.name === (name ? name : data.name)
    );
    newValues[index].value = name ? data : data.value;
    setValues({ ...values, assetFields: newValues });
  };

  const handleDateChange = (date, name) => {
    validateForm(name, '');
    if (name === 'endDate') {
      setValues({ ...values, rental: { ...rental, [name]: date ? formatDate(date) : null } });
    } else if (
      name === 'registrationExpirationAtUtc' ||
      name === 'dotRegistrationExpirationAtUtc'
    ) {
      setValues({
        ...values,
        assetLicense: { ...assetLicense, [name]: date || null }
      });
    } else if (name === 'modelYear') {
      setValues({
        ...values,
        assetLicense: { ...assetLicense, [name]: date ? formatDate(date, 'YYYY') : null }
      });
    } else {
      setValues({ ...values, [name]: date || null });
    }
  };

  const onDataFromInput = (data) => setValues({ ...values, [data.name]: data.value });

  const validatedFields = {
    assetPrefix: useRef(null),
    drCode: useRef(null),
    location: useRef(null)
  };

  const formIsInvalid = () => Object.values(validationErrors).filter(Boolean).length;

  useEffect(() => {
    if (formIsInvalid() && savePressed) {
      const firstInvalidElem =
        validatedFields[Object.keys(validationErrors).find((key) => validationErrors[key])];

      if (firstInvalidElem) {
        const elemRectData = firstInvalidElem.current?.getBoundingClientRect();
        setTopPositionAction(elemRectData?.y);
      }

      setSavePressed(false);
    }
  }, [validationErrors]);

  const validateForm = (key, newMessage) => {
    if (key) {
      setValidationErrors((state) => ({ ...state, [key]: newMessage }));
      return;
    }
    let isValidForm = true;
    if (!values.assetPrefix?.id) {
      setValidationErrors((state) => ({
        ...state,
        assetPrefix: 'Type field is required'
      }));
      isValidForm = false;
    }
    if (!values.drCode || !values.drCode.trim()) {
      setValidationErrors((state) => ({
        ...state,
        drCode: 'DR Code field is required'
      }));
      isValidForm = false;
    }
    if (!values.drCode?.replace(/[^0-9]/g, '')) {
      setValidationErrors((state) => ({
        ...state,
        drCode: 'Digital part of the asset is required'
      }));
      isValidForm = false;
    }
    if (!id && !lastLocationHistory?.location?.siteCode) {
      setValidationErrors((state) => ({
        ...state,
        location: 'Location field is required'
      }));
      isValidForm = false;
    }
    return isValidForm;
  };

  const getImages = resources?.filter((resource) => resource.resourceType?.id === 1) || [];

  const handleFileChange = (images) => {
    const resources = values?.resources || [];
    const otherFiles = resources?.filter((resource) => resource['resourceType']?.id !== 1) || [];
    setValues({ ...values, resources: [...otherFiles, ...images] });
  };

  const getFiles = resources?.filter((resource) => resource.resourceType?.id === 2) || [];

  const onUpload = (files) => {
    const uniqueFilesArray = [...(resources?.length ? resources : []), ...files].filter(
      (currentFile, index, filesArray) =>
        filesArray.findIndex((file) => file.id === currentFile.id) === index
    );
    setValues({ ...values, resources: uniqueFilesArray });
  };

  const onDelete = (file) => {
    setValues({
      ...values,
      resources: resources.filter((resource) => resource.id !== file.id)
    });
  };

  const onAgree = () => {
    let valuesCopy = { ...values };
    if (!values?.lastLocationHistory?.location?.siteCode) {
      valuesCopy = {
        ...values,
        lastLocationHistory: {
          ...lastLocationHistory,
          id: null,
          location: defaultLocation
        }
      };
    }
    if (valuesCopy?.rental?.project?.id === '-') {
      valuesCopy = {
        ...valuesCopy,
        rental: {
          ...valuesCopy.rental,
          project: null
        }
      };
    }
    switch (dialogModalData.type) {
      case NEW_ASSET_TYPE:
        setDialogModalData({ isOpened: false });
        createAssetAction(valuesCopy).then((err) => {
          if (err?.errors) {
            setValidationErrors({ ...validationErrors, ...getValidationObj(err) });
          }
        });
        break;
      case UPDATE_ASSET_TYPE:
        setDialogModalData({ isOpened: false });
        updateAssetAction({ ...currentAsset, ...valuesCopy }).then((err) => {
          if (err?.errors) {
            setValidationErrors({ ...validationErrors, ...getValidationObj(err) });
          }
        });
        break;
      case WITHOUT_SAVING_TYPE:
        if (id) {
          history.goBack();
        } else {
          history.push(ASSETS_LIST_PATH);
        }
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(routeUrl);
        break;
      default:
        break;
    }
  };

  const onDismiss = () => setDialogModalData({ isOpened: false });

  const onCancel = () => {
    if (!formIsNotChanged) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else if (id) {
      history.push(`${ASSET_DETAILED_PATH}/${id}`);
    } else {
      history.push(ASSETS_LIST_PATH);
    }
  };

  const onCreate = () => {
    if (!validateForm()) {
      setSavePressed(true);
      return;
    }
    if (id) {
      setDialogModalData({ ...UPDATE_ASSET, isOpened: true });
    } else {
      setDialogModalData({ ...CREATE_NEW_ASSET, isOpened: true });
    }
  };

  const forbiddenStatusesForContentsField = ['Containable'];
  const areContentsAllowed = (status) => !forbiddenStatusesForContentsField.includes(status);

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
      <section className={styles.pageContainer} ref={containerRef}>
        <section className={styles.formWrapper}>
          {!isMobile && (
            <BreadcrumbsNav
              itemsArray={breadcrumbs}
              setDialogModalData={setDialogModalData}
              formIsChanged={!formIsNotChanged}
              setRouteUrl={setRouteUrl}
            />
          )}
          <div className={styles.headerBlock}>
            <h1>{id ? `Edit Asset` : 'Add Asset'}</h1>
            {isMobile && <BackButton onCancel={onCancel} />}
          </div>
          <h3>General Details</h3>
          <section className={styles.formRow}>
            <section className={`${styles.formColumn} ${styles.justifyTop}`}>
              <section className={styles.formCell}>
                <label>Name*</label>
                <div className={styles.inputWrapper} ref={validatedFields.assetPrefix}>
                  <InputSearchWithMultiselect
                    name="assetPrefix"
                    defaultValue={assetPrefix?.id || ''}
                    options={enrichDescriptionToName(assetPrefixes) || []}
                    onFilterSelect={onTypeSelect}
                    isInvalid={!!validationErrors.assetPrefix}
                    multiselect={false}
                    optionType="prefixType"
                  />
                  {!!validationErrors.assetPrefix && <span>{validationErrors.assetPrefix}</span>}
                </div>
              </section>
              <section className={styles.formCell}>
                <label>DR Code*</label>
                <div className={styles.inputWrapper}>
                  <TextField
                    name="drCode"
                    ref={validatedFields.drCode}
                    className={clsx(
                      styles.textInput,
                      styles.drCodeInput,
                      validationErrors.drCode ? styles.error : ''
                    )}
                    variant="outlined"
                    value={values.drCode || ''}
                    onChange={onInput}
                  />
                  <p
                    className={clsx(
                      styles.codeStatus,
                      values.drCode === initValues.drCode || availableDRCode.isAvailable
                        ? styles.available
                        : styles.inUse
                    )}>
                    {values.drCode === initValues.drCode || availableDRCode.isAvailable
                      ? 'Available!'
                      : 'Already in use!'}
                    <span className={styles.statusIndicator}></span>
                  </p>
                  {!!validationErrors.drCode && <span>{validationErrors.drCode}</span>}
                </div>
              </section>
              <section className={styles.formCell}>
                <label>Short Note</label>
                <div className={styles.inputWrapper}>
                  <TextField
                    name="shortNote"
                    className={styles.textInput}
                    variant="outlined"
                    value={values.shortNote || ''}
                    onChange={onInput}
                  />
                </div>
              </section>
              <section className={styles.formCell}>
                <label>Contained In</label>
                <div className={styles.inputWrapper}>
                  <SearchInputWithTags
                    displayName="drCode"
                    defaultInputValue={containedInAsset || {}}
                    options={assetOptions}
                    multiple={false}
                    onInputChange={onAssetFilterChange}
                    onSelect={(value) => {
                      excludedAssetIds.current = id
                        ? [+id, +value?.id || null]
                        : [+value?.id || null];
                      setValues({
                        ...values,
                        containedInAsset: value
                      });
                    }}
                  />
                </div>
              </section>
              <section className={styles.formCell}>
                <label>Grade</label>
                <div className={`${styles.inputWrapper} ${styles.select}`}>
                  <SelectInput
                    name="grade"
                    value={values.grade?.name || ''}
                    menuItems={enrichNameToId(grades) || []}
                    onSelect={onSelect}
                  />
                </div>
              </section>
              <section className={`${styles.formCell} ${styles.checkboxCell}`}>
                <label>Non-owned?</label>
                <Checkbox
                  classes={{ root: styles.checkbox, checked: styles.checked }}
                  name="isNonOwned"
                  onChange={() => setValues({ ...values, isNonOwned: !values.isNonOwned })}
                  value={values.isNonOwned}
                  checked={!!values.isNonOwned}
                />
              </section>
            </section>

            <section className={`${styles.formColumn} ${styles.justifyTop}`}>
              <section className={styles.formCell}>
                <label>Purchase Date</label>
                <div className={styles.picker}>
                  <StyledDatePicker
                    name="purchasedAtUtc"
                    value={values?.purchasedAtUtc || null}
                    onChange={handleDateChange}
                  />
                </div>
              </section>
              <section className={`${styles.formCell} ${styles.checkboxCell}`}>
                <label>Labeled?</label>
                <Checkbox
                  classes={{ root: styles.checkbox, checked: styles.checked }}
                  name="labeled"
                  onChange={() => setValues({ ...values, labeled: !values.labeled })}
                  value={values.labeled}
                  checked={!!values.labeled}
                />
              </section>
              <section className={styles.formCell}>
                <label>Purchased From</label>
                <div className={styles.inputWrapper}>
                  <TextField
                    name="purchasedFrom"
                    className={styles.textInput}
                    variant="outlined"
                    value={values.purchasedFrom || ''}
                    onChange={onInput}
                  />
                </div>
              </section>
              <section className={styles.formCell}>
                <label>Rental Status*</label>
                <div className={`${styles.inputWrapper} ${styles.select}`}>
                  <SelectInput
                    name="rentalStatus"
                    value={rental?.rentalStatus?.name || ''}
                    menuItems={enrichNameToId(rentalStatuses) || []}
                    onSelect={onRentalSelect}
                    isInvalid={!!validationErrors.rentalStatus}
                    disabled={values?.isInPicklist?.name !== 'No'}
                  />
                  {!!validationErrors.rentalStatus && <span>{validationErrors.rentalStatus}</span>}
                </div>
              </section>
              {rental?.rentalStatus?.name === 'InTransit' &&
                values?.isInPicklist?.name === 'No' &&
                values?.isInLeavelist?.name === 'No' && (
                  <section className={styles.formCell}>
                    <label>Destination</label>
                    <div className={`${styles.inputWrapper} ${styles.select}`}>
                      <SelectInput
                        name="destination"
                        value={rental?.destination?.id || ''}
                        menuItems={locations || []}
                        onSelect={onRentalSelect}
                      />
                    </div>
                  </section>
                )}
              {rental?.rentalStatus?.name === 'Reserved' && (
                <section className={styles.formCell}>
                  <label>Project</label>
                  <div className={styles.inputWrapper}>
                    <InputSearchWithMultiselect
                      name="project"
                      defaultValue={rental?.project?.id || ''}
                      options={projectList || []}
                      onFilterSelect={onProjectSelect}
                      multiselect={false}
                      disabled={values?.isInPicklist?.name !== 'No'}
                    />
                  </div>
                </section>
              )}
              {(rental?.rentalStatus?.name === 'HoldForHigh' ||
                rental?.rentalStatus?.name === 'HoldForLow') && (
                <>
                  <section className={styles.formCell}>
                    <label>Project Name</label>
                    <div className={styles.inputWrapper}>
                      <InputSearchWithMultiselect
                        name="project"
                        defaultValue={rental?.project?.id || ''}
                        options={projectOptions || []}
                        onFilterSelect={onProjectSelect}
                        multiselect={false}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Duration</label>
                    <div className={styles.picker}>
                      <StyledDatePicker
                        name="endDate"
                        value={rental?.endDate || null}
                        onChange={handleDateChange}
                        min={moment(new Date()).add(-1, 'd')}
                      />
                    </div>
                  </section>
                </>
              )}
              <section className={styles.labelCell}>
                <label>Is in Picklist?</label>
                <span className={styles.helpLabel}>{values.isInPicklist?.name}</span>
              </section>
              <section className={styles.labelCell}>
                <label>Is in Leavelist?</label>
                <span className={styles.helpLabel}>{values?.isInLeavelist?.name}</span>
              </section>
              {values?.isInLeavelist?.name !== 'No' && (
                <section className={styles.labelCell}>
                  <label>Destination</label>
                  <span className={styles.helpLabel}>{rental?.destination?.siteCode || ''}</span>
                </section>
              )}
            </section>
          </section>

          <h3>{assetPrefix?.prefixType?.name || 'Container'} Details</h3>
          <section className={`${styles.formRow}`}>
            {getDetails()?.length &&
              getDetails().map((column, index) => (
                <section className={`${styles.formColumn} ${styles.justifyTop}`} key={index}>
                  {column?.map((cell, indx) => (
                    <section key={indx}>
                      <section className={styles.formCell}>
                        <label>{cell.displayName}</label>

                        {(() => {
                          switch (cell.type) {
                            case 'String':
                            case 'Int':
                            case 'Float':
                              return (
                                <div className={styles.inputWrapper}>
                                  <TextField
                                    name={getAssetIndex(cell.id)}
                                    className={styles.textInput}
                                    variant="outlined"
                                    value={getAssetValue(cell.id) || ''}
                                    onChange={(e) => onDetailsInput(e, cell)}
                                  />
                                </div>
                              );
                            case 'DateTime':
                              return (
                                <div className={styles.picker}>
                                  <StyledDatePicker
                                    name={cell.name}
                                    value={cell.value || null}
                                    onChange={(data) => onDetailsSelect(data, cell.name)}
                                  />
                                </div>
                              );
                            case 'Bool':
                              return (
                                <Checkbox
                                  classes={{
                                    root: styles.checkbox,
                                    checked: styles.checked
                                  }}
                                  name={cell.name}
                                  onChange={onDetailsCheck}
                                  value={
                                    assetFields.find(({ assetField }) => assetField.id === cell.id)
                                      ?.value
                                  }
                                  checked={
                                    !!assetFields.find(
                                      ({ assetField }) => assetField.id === cell.id
                                    )?.value
                                  }
                                />
                              );
                            case 'Enum':
                              return (
                                <div className={`${styles.inputWrapper} ${styles.select}`}>
                                  <SelectInput
                                    name={cell.name}
                                    value={
                                      assetFields.find(
                                        ({ assetField }) => assetField.id === cell.id
                                      )?.value?.id || ''
                                    }
                                    menuItems={
                                      assetPrefixes
                                        ?.find((asset) => asset.id === assetPrefix?.id)
                                        ?.assetFields?.find((asset) => asset.name === cell.name)
                                        ?.availableValues || []
                                    }
                                    onSelect={onDetailsSelect}
                                  />
                                </div>
                              );
                            default:
                              return null;
                          }
                        })()}
                      </section>
                    </section>
                  ))}
                </section>
              ))}
          </section>

          {(assetPrefix?.prefixType?.name === 'Vehicle' ||
            assetPrefix?.prefixType?.name === 'Trailer') && (
            <>
              <h3>License Information</h3>
              <section className={styles.formRow}>
                <section className={`${styles.formColumn} ${styles.justifyTop}`}>
                  <section className={styles.formCell}>
                    <label>State</label>
                    <div className={`${styles.inputWrapper} ${styles.select}`}>
                      <SelectInput
                        name="licenseState"
                        value={assetLicense?.licenseState?.id || ''}
                        menuItems={states || []}
                        onSelect={onSelect}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Plate number</label>
                    <div className={styles.inputWrapper}>
                      <TextField
                        name="licensePlate"
                        className={styles.textInput}
                        variant="outlined"
                        value={assetLicense?.licensePlate || ''}
                        onChange={onInput}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Registration Expiration</label>
                    <div className={styles.picker}>
                      <StyledDatePicker
                        name="registrationExpirationAtUtc"
                        value={assetLicense?.registrationExpirationAtUtc || null}
                        onChange={handleDateChange}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>DOT Registration Expiration</label>
                    <div className={styles.picker}>
                      <StyledDatePicker
                        name="dotRegistrationExpirationAtUtc"
                        value={assetLicense?.dotRegistrationExpirationAtUtc || null}
                        onChange={handleDateChange}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Make</label>
                    <div className={styles.inputWrapper}>
                      <TextField
                        name="make"
                        className={styles.textInput}
                        variant="outlined"
                        value={assetLicense?.make || ''}
                        onChange={onInput}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Model</label>
                    <div className={styles.inputWrapper}>
                      <TextField
                        name="model"
                        className={styles.textInput}
                        variant="outlined"
                        value={assetLicense?.model || ''}
                        onChange={onInput}
                      />
                    </div>
                  </section>
                  <section className={styles.formCell}>
                    <label>Model year</label>
                    <div className={styles.picker}>
                      <StyledDatePicker
                        name="modelYear"
                        value={
                          assetLicense?.modelYear
                            ? new Date(assetLicense?.modelYear, 1, 1, 0, 0, 0, 0)
                            : null
                        }
                        format="YYYY"
                        onChange={handleDateChange}
                      />
                    </div>
                  </section>
                </section>
              </section>
            </>
          )}

          <h3>Files</h3>
          <section className={styles.formRow}>
            <section className={styles.formColumn}>
              <section className={`${styles.formCell} ${styles.alignTop}`}>
                <label>Upload Photo</label>
                <ImagesUpload images={getImages} onChange={handleFileChange} />
              </section>
            </section>
            <section className={styles.formColumn}>
              <section className={`${styles.formCell} ${styles.alignTop}`}>
                <label>Upload File</label>
                <UploadFiles
                  uploadedFiles={getFiles}
                  onUploadFile={onUpload}
                  onDeleteFile={onDelete}
                />
              </section>
            </section>
          </section>

          <h3>Location</h3>
          <section className={styles.formRow}>
            <section className={`${styles.formColumn} ${styles.justifyTop}`}>
              <section className={styles.formCell}>
                <label>Current Location</label>
                <div className={styles.inputWrapper}>
                  <TextField
                    name="currentLocation"
                    className={styles.textInput}
                    variant="outlined"
                    value={initValues.lastLocationHistory?.location?.siteCode || ''}
                    disabled
                  />
                </div>
              </section>
              <section className={styles.formCell}>
                <label>Change location to{!id && '*'}</label>
                <div className={styles.inputWrapper} ref={validatedFields.location}>
                  <InputSearchWithMultiselect
                    name="location"
                    defaultValue={changeLocation?.location?.id || ''}
                    options={locations || []}
                    onFilterSelect={onChangeLocationSelect}
                    isInvalid={validationErrors.location}
                    multiselect={false}
                  />
                  {!!validationErrors.location && <span>{validationErrors.location}</span>}
                </div>
              </section>
              <section className={styles.formCell}>
                <label>Sublocation</label>
                <div className={styles.inputWrapper}>
                  <InputSearchWithMultiselect
                    name="sublocation"
                    defaultValue={changeLocation?.sublocation?.id || ''}
                    options={changeLocation?.location?.sublocations || []}
                    onFilterSelect={onChangeLocationSelect}
                    refreshKey={refreshKey}
                    multiselect={false}
                  />
                </div>
              </section>
            </section>
            <section className={`${styles.formColumn} ${styles.justifyTop}`}>
              <label>Location Change Notes</label>
              <div className={styles.multiTextInput}>
                <TextField
                  name="changeNotes"
                  multiline
                  minRows={4}
                  variant="outlined"
                  value={lastLocationHistory?.changeNotes || ''}
                  onChange={onInput}
                />
                <span className={styles.multiTextInput__counter}>
                  {lastLocationHistory?.changeNotes ? lastLocationHistory.changeNotes.length : 0}
                  /15000
                </span>
              </div>
              <p>
                The &quot;Location Change Notes&quot; field is used only if updating the location.
                If you want to keep the asset at the same location while updating the location notes
                for the asset, set the &quot;Change location to&quot; field to the current location
                and enter any applicable notes here.
              </p>
            </section>
          </section>

          {areContentsAllowed(assetPrefix?.prefixType?.name) && (
            <>
              <h3>Contents</h3>
              <section className={styles.formRow}>
                <section className={`${styles.formColumn} ${styles.justifyTop}`}>
                  <label>
                    Enter Asset DR Codes separated by spaces; codes must be valid DR Codes:
                  </label>
                  <InputDRCodeWithChips
                    inputName="contents"
                    inputValues={contents || []}
                    handleChanges={onDataFromInput}
                    excludedIds={[...excludedAssetIds.current].filter(Boolean)}
                  />
                  <p>
                    Only change this field if you want to change the items in this container. If you
                    want to keep the contents the same, leave this field as-is.
                  </p>
                </section>
              </section>
            </>
          )}

          <h3>Notes</h3>
          <section className={styles.formRow}>
            <section className={clsx(styles.formColumn, styles.justifyTop, styles.inputWrapper)}>
              <div className={clsx(styles.multiTextInput)}>
                <TextField
                  name="notes"
                  className={styles.multiTextInput}
                  multiline
                  minRows={4}
                  variant="outlined"
                  value={values.notes || ''}
                  onChange={onInput}
                />
                <span className={styles.multiTextInput__counter}>
                  {values?.notes ? values.notes.length : 0}
                  /15000
                </span>
              </div>
            </section>
          </section>
        </section>
        <div className={styles.footerControls}>
          <MainButton text="cancel" type="secondary" action={onCancel} />
          <MainButton
            text={id ? 'save' : 'create'}
            type="primary"
            action={onCreate}
            isDisabled={!!formIsInvalid()}
          />
        </div>
      </section>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  currentAsset: selectSingleAssetData(),
  activeAssetPrefix: selectActiveAssetPrefixCode(),
  drCodeData: selectActiveAssetDRCode(),
  assetsFromSearchData: selectAssetsFromSearch(),
  creationFormData: selectCreationFormData(),
  projectList: selectProjectListData()
});

const mapDispatchToProps = {
  setUnsavedFormDataAction: setUnsavedFormData,
  getCurrentAssetAction: getSingleAsset,
  getCreationFormAction: getAssetCreationForm,
  getAvailableDRCodeAction: getAvailableDRCode,
  getProjectListAction: getProjectList,
  searchAssetByStringAction: searchAssetByString,
  createAssetAction: createAsset,
  updateAssetAction: updateAsset,
  setTopPositionAction: setTopPosition,
  clearAssetsStateAction: clearAssetsState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AssetEdit);
