import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Chip } from '@material-ui/core';
import { searchDrCodesByString } from 'actions/assetActions';
import { selectDrCodesFromSearch } from './selectors';
import { allKeyboardLatSymbols, pluck } from 'helpers/AppHelpers';
import CloseIcon from '@material-ui/icons/Close';
import styles from './InputDRCodeWithChips.module.scss';
import CheckIcon from '@material-ui/icons/Check';

const DRCODE_MAXIMUM_LENGTH = 20;

function InputDRCodeWithChips({
  inputName,
  inputValues,
  handleChanges,
  isInvalid,
  refreshKey,
  drCodesFromSearchData,
  searchDrCodeByStringAction,
  excludedIds
}) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    pagination: {
      limit: 20,
      page: 1
    },
    filters: { isDeleted: false, searchQuery: '' }
  });
  const [chips, setChips] = useState([]);
  const [warningText, setWarningText] = useState('');

  useEffect(() => {
    setChips(inputValues);
  }, [inputValues]);

  useEffect(() => {
    if (drCodesFromSearchData?.items?.length) {
      setOpen(true);
    }
  }, [drCodesFromSearchData?.items]);

  const onInput = (event) => {
    if (event?.type === 'blur' || event?.type === 'click' || event === null) return;
    if (event?.target?.value?.length || event?.target?.value === '') {
      event.target.value = event.target.value
        ?.replace(allKeyboardLatSymbols, '')
        .substr(0, DRCODE_MAXIMUM_LENGTH);

      setValues({
        ...values,
        filters: {
          ...values.filters,
          excludeAssetIds: [excludedIds],
          searchQuery: event.target.value
        }
      });

      setWarningText('');
    }
    if (event?.target?.value !== '') {
      searchDrCodeByStringAction({
        ...values,
        filters: {
          ...values.filters,
          excludeAssetIds: [excludedIds],
          searchQuery: event?.target?.value
        }
      });
    }
  };

  const handleSelect = (e, value) => {
    const selectedIds = pluck(inputValues, 'id');
    if (!selectedIds?.includes(value.id)) {
      handleChanges({
        name: inputName,
        value: [...inputValues, value]
      });
      setChips([...chips, value]);
    } else {
      handleChanges({
        name: inputName,
        value: inputValues.filter(({ id }) => id !== value.id)
      });
      setChips(chips.filter(({ id }) => id !== value.id));
    }
  };

  const handleChipDelete = (item) => {
    const newValues = chips.filter((value) => value.id !== item.id);
    handleChanges({
      name: inputName,
      value: newValues
    });
    setChips(newValues);
  };

  const isOptionSelected = (option) => {
    const selectedIds = pluck(inputValues, 'id');
    return !!selectedIds?.includes(option.id);
  };

  return (
    <section
      className={`${styles.selectInput} ${isInvalid && styles.invalid}`}
      key={refreshKey || 1}>
      <Autocomplete
        classes={{
          inputRoot: styles.inputRoot,
          noOptions: styles.noOptions,
          popper: styles.popper,
          popupIndicator: styles.popupIndicator,
          tag: styles.tag,
          option: styles.mui_option
        }}
        open={open}
        multiple={false}
        id={inputName}
        options={drCodesFromSearchData?.items || []}
        inputValue={values.filters.searchQuery || ''}
        onInputChange={onInput}
        onChange={handleSelect}
        disableClearable
        value={inputValues}
        noOptionsText={
          !drCodesFromSearchData?.items?.length && values.filters.searchQuery && 'No matches'
        }
        popupIcon={<></>}
        closeIcon={<></>}
        getOptionLabel={(option) => option?.drCode || ''}
        renderOption={(option) => (
          <div className={styles.option}>
            <span>{option?.drCode || ''}</span>
            {isOptionSelected(option) && <CheckIcon fontSize="small" />}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            onBlur={() => open && setOpen(false)}
            onClick={() => drCodesFromSearchData?.items?.length && setOpen(true)}
          />
        )}
        renderTags={() => {
          return <></>;
        }}
      />

      <p className={styles.warningText}>{warningText || `\u2000`}</p>

      <section className={styles.chipsBlock}>
        {!!chips?.length &&
          chips.map((item, index) => (
            <Chip
              classes={{ icon: styles.chipIcon }}
              key={index}
              label={item.drCode}
              deleteIcon={<CloseIcon />}
              className={styles.fieldsChip}
              onDelete={() => handleChipDelete(item)}
            />
          ))}
      </section>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  drCodesFromSearchData: selectDrCodesFromSearch()
});

const mapDispatchToProps = {
  searchDrCodeByStringAction: searchDrCodesByString
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(InputDRCodeWithChips);
