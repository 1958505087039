import React from 'react';
import styles from './components.module.scss';
import { MainButton } from 'components/StyledComponents';
import { useUserConfig } from 'hooks/useUserConfig';
import { usePrefixActions, usePrefixSelector } from 'hooks/Prefix';
import { ASSET_EDIT_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';

export default function Footer() {
  const history = useHistory();
  const { isBasicRoleUser } = useUserConfig();

  const { setActiveAssetPrefixAction } = usePrefixActions();
  const { currentPrefix } = usePrefixSelector();
  const { prefixType } = currentPrefix;

  const handelAddAssetsClick = () => {
    setActiveAssetPrefixAction(currentPrefix);
    history.push(ASSET_EDIT_PATH);
  };

  return (
    <div className={styles.footer}>
      {!isBasicRoleUser && (
        <MainButton
          text={`Add asset in ${prefixType?.name}`}
          action={handelAddAssetsClick}
          type="primary"
          width="long"
        />
      )}
    </div>
  );
}
