import api from '../api';
import { enqueueErrorSnackbar } from '../helpers/AppHelpers';
import { ERROR_CANT_FIND_ASSETS } from 'constants/infoSnackbarData';

export const searchAssetByString = (searchQuery, options) => () => {
  const query = {
    filters: { isDeleted: false, searchQuery, ...options }
  };
  return api.asset
    .searchAssetBySrtingApi(query)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const searchAssetsByDRCodes = (array, index, options) => () => {
  const query = { value: { drCodes: array, isDeleted: false, ...options }, index };
  return api.asset
    .searchAssetsByDRCodesApi(query.value)
    .then((res) => {
      const { assets, notFoundDrCodes } = res.data;
      if (notFoundDrCodes.length) {
        enqueueErrorSnackbar(ERROR_CANT_FIND_ASSETS + ' ' + notFoundDrCodes.join(', '));
      }
      return { value: assets, index: query.index };
    })
    .catch((err) => console.log(err));
};
