import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { Provider } from 'react-redux';
import store from './store';
import { browserHistory } from './browserHistory';
import { Router } from 'react-router-dom';
import { env } from './api/api';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './msConfig';

import * as Sentry from '@sentry/react';

const msalInstance = new PublicClientApplication(msalConfig);

if (['production'].includes(env)) {
  Sentry.init({
    dsn: 'https://83d113ba822a00f952bb6080591bbbb4@o4506270157438976.ingest.sentry.io/4506270159208448',
    environment: process.env.REACT_APP_ENVIRONMENT || 'local',
    ignoreErrors: [/Network Error/i],
    integrations: (integrations) => {
      return integrations
        .filter((i) => i.name !== 'Dedupe')
        .filter((i) => i.name !== 'GlobalHandlers')
        .filter((i) => i.name !== 'TryCatch');
    }
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={browserHistory}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
