import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styles from './FormSelectInput.module.scss';
import SelectInput from 'components/SelectInput/SelectInput';
import clsx from 'clsx';

export default function FormSelectInput({
  name,
  options,
  onSelect,
  isDisabled,
  classes,
  clearable,
  index,
  arrayValue,
  enableHint,
  getHint,
  hintText
}) {
  const { control, clearErrors } = useFormContext();

  const handleOptionSelect = ({ value }, isInvalid) => {
    isInvalid && clearErrors(name);
    onSelect({ name, value: arrayValue ? (value?.id ? [value.id] : []) : value, index });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <div className={clsx(!!classes && classes, styles.inputWrapper)}>
          <SelectInput
            name={name}
            value={arrayValue ? value[0] || '' : value?.id || ''}
            menuItems={options}
            onSelect={(value) => handleOptionSelect(value, !!error?.message)}
            isInvalid={!!error}
            disabled={isDisabled}
            clearable={clearable}
          />
          {error ? (
            <span className={styles.error}>{error.message}</span>
          ) : enableHint ? (
            <span className={styles.hint}>{getHint ? getHint(value) : hintText}</span>
          ) : null}
        </div>
      )}
    />
  );
}
