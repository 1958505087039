import React, { useEffect } from 'react';
import styles from './GeneralReportEdit.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useReportSelector, useReportActions } from 'hooks/ReportList';

import GeneralReportDetails from './components/GeneralDetails';
import ReportEditWrapper from '../../commonComponents/ReportEditWrapper';

import { formatDate, wait } from 'helpers/AppHelpers';
import { useSearchParams } from 'hooks/useSearchParams';

export default function GeneralReportEdit() {
  const { id } = useParams();

  const { generalReportTemplateId } = useSearchParams(['generalReportTemplateId']);

  const { startAutoSaveAction, getCreationFormAction } = useReportActions();
  const { currentGeneralReport } = useReportSelector();

  const startAutoSave = () => {
    wait(1000).then(() => startAutoSaveAction());
  };

  const methods = useForm({
    defaultValues: {
      reportType: {},
      generalReportTemplate: {},
      generalReportFields: [],
      resources: [],
      assignedAtUtc: null,
      signatures: []
    },
    mode: 'onChange'
  });
  const { reset, getValues } = methods;

  useEffect(() => {
    if (id) return;

    if (!generalReportTemplateId) return;

    getCreationFormAction({ generalReportTemplateId }).then((res) => {
      if (!res?.generalReportTemplate?.id) return;

      const { generalReportTemplate } = res;
      const reportFields = generalReportTemplate?.generalReportFields.sort(
        (a, b) => a.order - b.order
      );

      const presetValues = {
        ...getValues(),
        createdAtUtc: formatDate(new Date()),
        reportType: { ...generalReportTemplate?.reportType },
        generalReportTemplate: { id: generalReportTemplate?.id, name: generalReportTemplate?.name },
        generalReportFields: [...reportFields]
      };

      reset(presetValues);
      startAutoSave();
    });
  }, []);

  useEffect(() => {
    if (id && currentGeneralReport?.id) {
      reset(currentGeneralReport);
      if (!currentGeneralReport.submitted) {
        startAutoSave();
      }
    }
  }, [currentGeneralReport]);

  return (
    <section className={styles.wrapper}>
      <FormProvider {...methods}>
        <ReportEditWrapper reportType="general">
          <GeneralReportDetails />
        </ReportEditWrapper>
      </FormProvider>
    </section>
  );
}
