import {
  CLEAR_PICKLIST_STATE,
  SET_BILL_OF_LADING_CREATION_FORM,
  SET_CURRENT_PICKLIST,
  SET_LOAD_MORE_FLAG,
  SET_LOCATIONS_FOR_PICK_LIST,
  SET_PICKLIST_FILTER,
  SET_PICKLIST_ASSETS_COUNTING,
  SET_PICKLIST_DR_CODES,
  SET_PICKLIST_STATUSES,
  SET_PICKLIST_TRUCK_OR_TRAILERS,
  SET_PROJECTS_FOR_PICK_LIST,
  SET_STATUSES_FOR_PICK_LIST
} from '../constants/reduceConstants';
import api from '../api';
import {
  SUCCESS_CREATE_PICKLIST,
  SUCCESS_DELETE_PICKLIST,
  SUCCESS_UPDATE_PICKLIST
} from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import {
  BILL_OF_LADING_EDIT_PATH,
  PICKLIST_DETAILED_PATH,
  PICKLIST_SUMMARY_PATH
} from '../constants/routeConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

export const getPicklists = (query) => (dispatch) =>
  api.picklist
    .getPicklistsApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getSinglePicklist = (id) => (dispatch) => {
  api.picklist
    .getSinglePicklistApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_PICKLIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getFilterCriteria = () => () => {
  return api.picklist
    .getFilterCriteriaApi()
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getPicklistAssetsCounting = (query) => (dispatch) => {
  return api.picklist
    .getPicklistAssetsCountingApi(query)
    .then((res) => dispatch({ type: SET_PICKLIST_ASSETS_COUNTING, payload: res }))
    .catch((err) => console.log(err));
};

export const getLocations = (query) => (dispatch) => {
  api.picklist
    .getLocationsApi(query)
    .then((res) => dispatch({ type: SET_LOCATIONS_FOR_PICK_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getAssets = (query) => () => {
  return api.picklist
    .getAssetsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getPicklistDetailsFilterCriteria = (query) => () =>
  api.picklist
    .getPicklistDetailsFilterCriteriaApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getStatuses = (query) => (dispatch) => {
  api.picklist
    .getStatusesApi(query)
    .then((res) => dispatch({ type: SET_STATUSES_FOR_PICK_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const deleteFileFromAsset = (fileId) => () =>
  api.common
    .deleteFileApi(fileId)
    .then((res) => res)
    .catch((err) => console.log(err));

export const searchTruckOrTrailer = (query) => (dispatch) => {
  api.asset
    .searchAssetBySrtingApi(query)
    .then((res) => dispatch({ type: SET_PICKLIST_TRUCK_OR_TRAILERS, payload: res.data }))
    .catch((err) => console.log(err));
};

export const searchDrCodes = (query) => (dispatch) => {
  api.picklist
    .searchPicklistAssetsApi(query)
    .then((res) => dispatch({ type: SET_PICKLIST_DR_CODES, payload: res.data }))
    .catch((err) => console.log(err));
};

export const getProjects = (query) => (dispatch) => {
  api.picklist
    .getProjectsApi(query)
    .then((res) => dispatch({ type: SET_PROJECTS_FOR_PICK_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const createPicklist = (data) => () => {
  return api.picklist.createPicklistApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_PICKLIST);
      browserHistory.push(`${PICKLIST_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updatePicklist = (data, isDetailsScreen, shouldShowPopup) => (dispatch) => {
  return api.picklist.updatePicklistApi(data).then(
    (res) => {
      shouldShowPopup && enqueueSuccessSnackbar(SUCCESS_UPDATE_PICKLIST);
      !isDetailsScreen && browserHistory.push(`${PICKLIST_DETAILED_PATH}/${res.id}`);
      dispatch({ type: SET_CURRENT_PICKLIST, payload: res });
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updatePicklistAsset = (data) => () => {
  return api.picklist.updatePicklistAssetApi(data).then(
    (res) => {
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deletePicklist = (id) => () => {
  return api.picklist.deletePicklistApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_PICKLIST);
      browserHistory.push(PICKLIST_SUMMARY_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const deleteItems = (data) => () => {
  return api.picklist.deleteItemsApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const checkBillOfLadingItems = (data) => (dispatch) => {
  return api.picklist.checkBillOfLadingItemsApi(data).then(
    (res) => {
      if (res.status === 200) {
        dispatch({ type: SET_BILL_OF_LADING_CREATION_FORM, payload: res.data });
        browserHistory.push(BILL_OF_LADING_EDIT_PATH);
      }
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const checkMatchingLocation = (query) => () =>
  api.picklist
    .checkPicklistAssetsLocationApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getPicklistAssetStatuses = (query) => (dispatch) => {
  api.picklist
    .getAssetStatusesListApi(query)
    .then((res) => dispatch({ type: SET_PICKLIST_STATUSES, payload: res }))
    .catch((err) => console.log(err));
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_PICKLIST_STATE });
};

export const setFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_PICKLIST_FILTER, payload });
};
