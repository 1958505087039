import React from 'react';
import styles from './Form.module.scss';

import { Chip, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { usePrefixActions } from 'hooks/Prefix';

import refreshIcon from 'assets/images/refreshIcon.svg';
import CloseIcon from '@material-ui/icons/Close';

import clsx from 'clsx';

export default function FieldsContainer({ refreshOptions, showDeleteModal }) {
  const { id } = useParams();
  const { setValue, getValues } = useFormContext();

  const { getFieldsDeletePermissionsAction } = usePrefixActions();

  const handleChipDelete = (name, chip) => {
    const isCreateMode = !id;

    const removeChip = () => {
      const param = chip?.id ? 'id' : 'name';
      setValue(
        'assetFields',
        getValues('assetFields').filter((el) => el[param] !== chip[param])
      );
    };

    const restoreChip = () => {
      const field = { ...getValues(name), deleted: false };
      setValue(name, field);
      refreshOptions(field);
    };

    const deleteChip = () => {
      const field = { ...getValues(name), deleted: true };
      setValue(name, field);
      refreshOptions(field);
    };

    if (isCreateMode) {
      removeChip();
      return;
    }
    if (chip.deleted) {
      restoreChip();
      return;
    }
    if (!chip.id) {
      removeChip();
      return;
    }
    getFieldsDeletePermissionsAction(id, chip.id).then((res) => {
      if (res?.canBeDeleted) {
        deleteChip();
      } else {
        showDeleteModal(name, { ...getValues(name), deleted: true });
      }
    });
  };

  return (
    <Controller
      name="assetFields"
      render={({ field: { value } }) =>
        !!value?.length && (
          <TableContainer className={styles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell>Is Numeric?</StyledTableCell>
                  <StyledTableCell>Show on Asset Summary</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {value.map((field, index) => (
                  <StyledTableRow key={field.id || field.name || field.displayName}>
                    <StyledTableCell>
                      <Controller
                        name={`assetFields[${index}]`}
                        render={({ field: { name, value } }) => (
                          <Chip
                            classes={{
                              root: value.deleted ? styles.redChip : styles.blueChip
                            }}
                            deleteIcon={
                              value.deleted ? (
                                <img
                                  src={refreshIcon}
                                  className={styles.refreshIcon}
                                  alt="refresh"
                                />
                              ) : (
                                <CloseIcon />
                              )
                            }
                            label={value.displayName || value.name || ''}
                            className={clsx(styles.chip, value.deleted && styles.deleted)}
                            onDelete={() => handleChipDelete(name, value)}
                          />
                        )}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <CustomCheckbox value={!!field['isNumeric']} isDisabled />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormCheckbox name={`assetFields[${index}].isShowOnAssetSummary`} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
    />
  );
}
