import React, { useRef } from 'react';
import styles from './AssetOpenedTickets.module.scss';

import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import StatusIndicator from 'components/StatusIndicator';

import { TICKET_DETAILED_PATH } from 'constants/routeConstants';

import { dateTimeFormat, formatDate, getUserFullName } from 'helpers/AppHelpers';
import { getChangedByPageFilter } from 'helpers/SummaryPagesHelpers';

import { ASSET_CLOSED_TICKETS_TABLE_PARAMETER } from 'constants/configTableConstants';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';

export default function AssetClosedTickets() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const { getTableLimit } = useUserConfig();

  const { closedTickets, closedTicketsFilter } = useAssetSelector();
  const { getClosedTickets } = useAssetActions();

  const emptyResponse = () =>
    !closedTickets?.items?.length &&
    closedTicketsFilter?.pagination?.page === closedTickets?.pageNumber;

  const changePage = (page, param) =>
    getClosedTickets(getChangedByPageFilter(closedTicketsFilter, page), param);

  const getUpdatedByLimitFilter = () => ({
    ...closedTicketsFilter,
    pagination: {
      ...closedTicketsFilter.pagination,
      page: 1,
      limit: getTableLimit(ASSET_CLOSED_TICKETS_TABLE_PARAMETER)
    },
    isDataReceived: false
  });

  return (
    <>
      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Asset</StyledTableCell>
              <StyledTableCell>Ticket ID</StyledTableCell>
              <StyledTableCell>Ticket Type</StyledTableCell>
              <StyledTableCell>Ticket Severity</StyledTableCell>
              <StyledTableCell>Ticket Description</StyledTableCell>
              <StyledTableCell>Ticket Closed By</StyledTableCell>
              <StyledTableCell>Ticket Closed On</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {closedTickets?.items?.length ? (
              closedTickets.items.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.asset?.drCode || ''}</StyledTableCell>
                  <StyledTableCell>
                    <LinkComponent
                      name={row.id}
                      path={`${TICKET_DETAILED_PATH}/${row.id}`}
                      isRedirectAvailable={!row.deleted}
                      boldText
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row.type}</StyledTableCell>
                  <StyledTableCell>
                    <StatusIndicator status={row.severity?.name} />
                  </StyledTableCell>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell>{getUserFullName(row['closedByUser'])}</StyledTableCell>
                  <StyledTableCell>
                    {row['closedAtUtc'] ? formatDate(row['closedAtUtc'], dateTimeFormat) : ''}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={10} className={styles.empty}>
                  No available data in the table.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={closedTickets?.items?.length}
          currentPage={closedTicketsFilter?.pagination?.page}
          totalPages={closedTicketsFilter?.pagination?.totalPages}
          selectAllOption
          onPageSelect={changePage}
          pageLimit={closedTicketsFilter?.pagination?.limit}
          hide={emptyResponse()}
          tableListParameter={ASSET_CLOSED_TICKETS_TABLE_PARAMETER}
          getTableData={() => getClosedTickets(getUpdatedByLimitFilter())}
          tableRef={tableRef}
        />
      </div>
    </>
  );
}
