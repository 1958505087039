import React, { useEffect, useState } from 'react';
import styles from './AddReport.module.scss';
import PropTypes from 'prop-types';

import FormServerAssetSearch from 'components/FormComponents/FormServerAssetSearch/FormServerAssetSearch';
import { FormLabel, FormRow, FormSearchInput, FormSelectInput } from 'components/FormComponents';
import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';

import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getLastSavedGeneralReport,
  getLocationsAndProjects,
  getShortedReportTemplates,
  searchReportTemplateByLocationId,
  searchTemplateByAssetId
} from 'actions/reportListActions';

import { getErrorsProperties } from 'helpers/ErrorValidator';

import { generateExistedReportUrl, generateUrl, REPORT_TYPES } from './helpers';
import { PRJPlate } from './PRJPlate';
import { getUserFullName } from 'helpers/AppHelpers';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { EXIST_REPORT } from 'constants/dialogPopupsData';

/**
 * @typedef {Object} AddGeneralReportPopupProps
 * @property {boolean} open
 * @property {() => void} setOpen
 * @property {'Asset' | 'Location' | 'General'} reportType
 */

/**
 * @param {AddGeneralReportPopupProps} props
 */

const CustomOption = (option, label) => (
  <div className={styles.select_option}>
    <span>{label}</span>
    {option?.project?.id && <PRJPlate />}
  </div>
);

function AddGeneralReportPopup({ open, setOpen, reportType }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [options, setOptions] = useState({});
  const [modalData, setModalData] = useState({});

  const methods = useForm({
    defaultValues: { template: {}, asset: {}, location: {} },
    mode: 'onChange'
  });
  const { getValues, setValue, formState, setError, clearErrors } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;
  const checkFieldError = (name) => errors[name]?.message && clearErrors(name);

  useEffect(() => {
    if (!open) return;

    if (reportType === REPORT_TYPES.GENERAL) {
      dispatch(getShortedReportTemplates({ filters: { generalReportTypeName: ['General'] } })).then(
        (res) => setOptions((prev) => ({ ...prev, templates: res }))
      );
    }

    if (reportType === REPORT_TYPES.LOCATION) {
      dispatch(getLocationsAndProjects()).then((res) =>
        setOptions((prev) => ({
          ...prev,
          locations: res.map((el) => ({ ...el, id: el.index + 1 }))
        }))
      );
    }
  }, [open]);

  const { templates, locations } = options;

  const selectTemplate = ({ name, value }) => {
    checkFieldError(name);
    setValue(name, value);
  };

  const selectAsset = (name, value) => {
    checkFieldError(name);
    setValue(name, value);

    if (value?.id) {
      dispatch(searchTemplateByAssetId(value.id)).then((res) => {
        setValue('template', res?.generalReportTemplates?.find(({ isDefault }) => isDefault) || {});
        setOptions((prev) => ({ ...prev, templates: res?.generalReportTemplates || [] }));
      });
    } else {
      setValue('template', {});
      setOptions((prev) => ({ ...prev, templates: [] }));
    }
  };

  const selectLocation = (name, value) => {
    checkFieldError(name);
    setValue(name, value);

    if (value?.name) {
      const { isProject, project, location } = value;
      const query = isProject ? { projectId: project.id } : { locationId: location.id };
      dispatch(searchReportTemplateByLocationId(query)).then((res) => {
        setValue('template', res?.find(({ isDefault }) => isDefault) || {});
        setOptions((prev) => ({ ...prev, templates: res || [] }));
      });
    } else {
      setValue('template', {});
      setOptions((prev) => ({ ...prev, templates: [] }));
    }
  };

  const validateForm = () => {
    const { template, asset, location } = getValues();

    const fieldsToValidate = [
      {
        name: 'template',
        errorMessage: 'Report name field is required',
        isInvalid: !template?.id
      },
      {
        name: 'asset',
        errorMessage: 'Asset field is required',
        isInvalid: !asset?.id,
        shouldCheck: reportType === REPORT_TYPES.ASSET
      },
      {
        name: 'location',
        errorMessage: 'Location field is required',
        isInvalid: !location?.name,
        shouldCheck: reportType === REPORT_TYPES.LOCATION
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid, shouldCheck = true }) => {
      if (isInvalid && shouldCheck) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const navigateToReportCreation = () => history.push(generateUrl(reportType, getValues()));

  const closePopup = () => setOpen(false);
  const applyPopup = () => {
    if (!validateForm()) return;

    if (reportType === REPORT_TYPES.LOCATION) {
      const { template, location } = getValues();
      const query = { generalReportTemplateId: template.id };

      if (location.isProject) {
        query.projectId = location.project.id;
      } else {
        query.locationId = location.location.id;
      }

      dispatch(getLastSavedGeneralReport(query)).then((res) => {
        if (!res?.lastSavedTodayGeneralReport?.id) {
          navigateToReportCreation();
          return;
        }

        const { lastSavedTodayGeneralReport } = res;
        const userName = getUserFullName(lastSavedTodayGeneralReport.createdByUser);
        setModalData({ ...EXIST_REPORT(userName), isOpened: true, reportData: res });
      });
    } else {
      navigateToReportCreation();
    }
  };

  const dismissModal = () => {
    setModalData({});
    history.push(generateExistedReportUrl(modalData.reportData.lastSavedTodayGeneralReport.id));
  };

  const agreeModal = () => {
    setModalData({});
    closePopup();

    navigateToReportCreation();
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader title="Create report" onClose={closePopup} />
        <FormProvider {...methods}>
          <div className={styles.form}>
            {reportType === REPORT_TYPES.ASSET && (
              <FormRow>
                <FormLabel required>Asset</FormLabel>
                <FormServerAssetSearch
                  name="asset"
                  max={100}
                  onSelect={selectAsset}
                  getLabel={(option) => option?.drCode || ''}
                  getQuery={(searchQuery) => ({
                    pagination: { limit: 39, page: 1 },
                    filters: { isDeleted: false, includeUserCheck: false, searchQuery }
                  })}
                />
              </FormRow>
            )}
            {reportType === REPORT_TYPES.LOCATION && (
              <FormRow>
                <FormLabel required>Location</FormLabel>
                <FormSearchInput
                  name="location"
                  options={locations}
                  onSelect={selectLocation}
                  RenderOption={CustomOption}
                />
              </FormRow>
            )}
            <FormRow>
              <FormLabel required>Report name</FormLabel>
              <FormSelectInput
                name="template"
                options={templates || []}
                onSelect={selectTemplate}
                isDisabled={!templates?.length}
              />
            </FormRow>
          </div>
        </FormProvider>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton text="Create" action={applyPopup} type="primary" isDisabled={isFormInvalid} />
        </div>
      </div>

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={dismissModal} />
    </DialogWrapper>
  );
}

AddGeneralReportPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired
};

AddGeneralReportPopup.defaultProps = {
  open: false,
  reportType: 'General'
};

export { AddGeneralReportPopup };
